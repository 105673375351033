<template>
  <div>
    <v-data-table
        :headers="headers"
        :footer-props="{'items-per-page-options':[10, 20, 50, 100, 200, 500]}"
        :items-per-page="queryData.limit"
        :items="listData.skus"
        :server-items-length="listData.total"
        class="elevation-1 custom-data-table"
        @update:sort-by="onSortByChange"
        @update:sort-desc="onSortDescChange"
        @update:page="changePage"
        @update:items-per-page="changePerPage"
        sort-by="stockOutTime"
    >
      <template v-slot:top>
        <div
            class="d-flex flex-wrap justify-center justify-md-space-between gap-4 px-4 py-4 border-b data-table-top">
          <div class="d-flex flex-column justify-center">
            <h5 class="text-h6">List Items</h5>
          </div>
          <div class="d-flex align-center flex-wrap">
            <v-btn color="default" @click="handleViewHistory" class="text-button ml-3">View History</v-btn>
          </div>
        </div>
      </template>

      <template v-slot:item.sku="{ item }">
        {{ item.sku }}
      </template>
      <template v-slot:item.quantityOut="{ item }">
        {{ item.quantityOut }}
      </template>
      <template v-slot:item.unit="{ item }">
        {{ item.unit }}
      </template>
      <template v-slot:item.priceOut="{ item }">
        {{ formatPrice(item.priceOut) }}
      </template>
      <template v-slot:item.totalValue="{ item }">
        {{ formatPrice(item.totalValue) }}
      </template>
      <template v-slot:item.description="{ item }">
        {{ item.description }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { apiManagement } from '@/services/apis/apiManagement'
import coreApiClient from '@/services/apis/coreApiClient'
import { convertToTimestamp, formatDateFromBE, formatPrice } from '@/utils/format'
import { debounce } from 'lodash'

export default {
  data: () => ({
    headers: [
      { text: 'SKU', sortable: false, value: 'sku' },
      { text: 'Q.TY OUT', value: 'quantityOut', align: 'end' },
      { text: 'Unit', value: 'unit' },
      { text: 'Price out', value: 'priceOut', align: 'end' },
      { text: 'Total value', value: 'totalValue', align: 'end' },
    ],
    listData: {
      data: [],
      count: 0,
    },
    queryData: {
      offset: 0,
      limit: 50,
      orderType: 'DESC',
      startDate: null,
      endDate: null,
      inventoryIds: [],
    },
    currentPage: 1,
  }),

  props: {
    actionGetList: {
      type: Number
    },
    rangeStockInTime: {
      type: Object
    },
    inventoryFilterIds: {
      type: Array
    },
  },

  watch: {
    actionGetList() {
      this.debouncedFetchData()
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    formatPrice,
    formatDateFromBE,
    debouncedFetchData: debounce(async function() {
      await this.getList()
      await this.getTotalValueOut()
    }, 300),

    initialize() {
      this.debouncedFetchData()
    },

    async getList() {
      this.queryData.startDate = this.rangeStockInTime.start ? convertToTimestamp(this.rangeStockInTime.start, 'DD/MM/YYYY') : null
      this.queryData.endDate = this.rangeStockInTime.end ? convertToTimestamp(this.rangeStockInTime.end, 'DD/MM/YYYY') : null
      this.queryData.inventoryIds = this.inventoryFilterIds

      const params = { ...this.queryData }
      try {
        const response = await apiManagement.getListStockOut(params)
        this.listData = response.data
      } catch (e) {
        console.log(e)
      }
    },

    async getTotalValueOut() {
      this.queryData.startDate = this.rangeStockInTime.start ? convertToTimestamp(this.rangeStockInTime.start, 'DD/MM/YYYY') : null
      this.queryData.endDate = this.rangeStockInTime.end ? convertToTimestamp(this.rangeStockInTime.end, 'DD/MM/YYYY') : null
      this.queryData.inventoryIds = this.inventoryFilterIds

      const params = { ...this.queryData }
      const response = await apiManagement.getTotalValueOut(params)
      this.$emit('setTotalValue', response.data.totalValue)
    },

    handleViewHistory() {
      this.$router.push('/app/inventory-stock-out/history')
    },

    changePage(value) {
      this.currentPage = value
      this.queryData.offset = this.queryData.limit * (value - 1)
      this.debouncedFetchData()
    },

    changePerPage(value) {
      this.queryData.limit = value
      this.debouncedFetchData()
    },

    onSortByChange(value) {
      this.queryData.orderBy = value || 'stockOutTime'
      this.debouncedFetchData()
    },

    onSortDescChange(value) {
      this.queryData.orderType = value === false ? 'ASC' : 'DESC'
      this.debouncedFetchData()
    },

    handleExport() {
      this.queryData.startDate = this.rangeStockInTime.start ? convertToTimestamp(this.rangeStockInTime.start, 'DD/MM/YYYY') : null
      this.queryData.endDate = this.rangeStockInTime.end ? convertToTimestamp(this.rangeStockInTime.end, 'DD/MM/YYYY') : null
      this.queryData.inventoryIds = this.inventoryFilterIds

      const params = { ...this.queryData }

      coreApiClient.download(
          'stock-out/list-skus',
          `export`,
          params,
      )
    },
  },
}
</script>

<style scoped lang="scss">
.custom-data-table {
  .data-table-top {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}

.table-action {
  display: flex;
  align-items: end;
  justify-content: start;
  height: 100%;
  gap: 28px;
}

.table-action p {
  cursor: pointer;
  color: #0B44D8;
}
</style>
