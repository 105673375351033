<template>
  <date-range-picker
      ref="picker"
      opens="left"
      :showDropdowns="true"
      :autoApply="true"
      :alwaysShowCalendars="false"
      v-model="dateRangeModel"
      @update="updateValues"
      :ranges="ranges"
  >
    <template v-slot:input="">
      <div class="d-flex justify-space-between" style="width: 210px">
        <span>{{ getDateRangeText() }}</span>
        <v-icon medium>
          mdi-chevron-down
        </v-icon>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { DateRangePicker },
  data() {
    const allTimeStart = new Date(0)
    const allTimeEnd = new Date()
    const dateFormat = 'DD/MM/YYYY'
    const allRanges = {
      'Today': [moment().startOf('date').toDate(), moment().startOf('date').toDate()],
      'Yesterday': [moment().subtract(1, 'day').startOf('date').toDate(), moment().subtract(1, 'day').startOf('date').toDate()],
      'This week': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
      'Last week': [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
      'All time': [allTimeStart, allTimeEnd],
      'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
      'Last 1 month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
      'Last 3 months': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
      'Last 6 months': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
      'This Year': [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
      'Last year': [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()]
    }
    const ranges = {}
    this.hasOptions.forEach(label => {
      ranges[label] = allRanges[label]
    })

    return {
      rangeResult: {
        start: moment(allRanges[this.defaultLabel][0]).format(dateFormat),
        end: moment(allRanges[this.defaultLabel][1]).format(dateFormat)
      },
      dateRangeModel: {
        startDate: allRanges[this.defaultLabel][0],
        endDate: allRanges[this.defaultLabel][1],
      },
      dateFormat,
      ranges,
    }
  },
  props: {
    hasOptions: {
      type: Array,
      default: () => [
        'All time',
        'This month',
        'Last 1 month',
        'Last 3 months',
        'Last 6 months',
        'This Year',
        'Last year'
      ]
    },
    defaultLabel: {
      type: String,
      default: 'All time'
    }
  },

  created() {
    this.$emit('change', this.rangeResult)
  },

  methods: {
    updateValues(value) {
      this.rangeResult.start = moment(value.startDate).format(this.dateFormat)
      this.rangeResult.end = moment(value.endDate).format(this.dateFormat)
      this.$emit('change', this.rangeResult)
    },
    getDateRangeText() {
      const startDate = this.rangeResult.start
      const endDate = this.rangeResult.end
      for (const key in this.ranges) {
        if (startDate === moment(this.ranges[key][0]).format(this.dateFormat) && endDate === moment(this.ranges[key][1]).format(this.dateFormat)) {
          return key
        }
      }

      return startDate + ' - ' + endDate
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
