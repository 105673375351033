






















































import BaseDateRangePicker from '@/components/BaseDateRangePicker.vue';
import CreateStockOut from '@/components/inventory/modals/CreateStockOut.vue';
import StockOutDataTable from '@/components/inventory/StockOutDataTable.vue';
import { apiManagement } from '@/services/apis/apiManagement';
import { formatPrice } from '@/utils/format';
import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  components: {CreateStockOut, BaseDateRangePicker, StockOutDataTable},
  data() {
    return {
      actionGetList: 0,
      searchQuery: '',
      rangeStockInTime: {
        start: null,
        end: null
      },
      optionDaterange: [
        'Today', 'Yesterday', 'This week', 'This month', 'Last 1 month', 'Last 3 months', 'Last 6 months', 'This Year', 'Last year'
      ],
      actionShowModalCreate: 0,
      actionShowModalDetail: 0,
      allSkuOptions: [],
      itemEdit: null,
      inventoryFilterIds: [],
      totalValueOut: 0,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    formatPrice,
    initialize() {
      this.getListSkuOption();
    },

    async getListSkuOption() {
      try {
        const response = await apiManagement.getAllSkuOptions();
        this.allSkuOptions = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    handleExport() {
      this.$refs.stockOutDataTable?.handleExport();
    },
    handleAddNew() {
      this.itemEdit = null;
      this.actionShowModalCreate++;
    },
    handleShowEdit(item) {
      this.itemEdit = item;
      this.actionShowModalCreate++;
    },
    handleShowDetail(item) {
      this.itemEdit = item;
      this.actionShowModalDetail++;
    },
    updateFilterStockinTime(value) {
      this.rangeStockInTime = {...value};
      this.actionGetList++;
    },
    changeFilterSKU() {
      this.actionGetList++;
    },
    setTotalValueOut(value) {
      this.totalValueOut = value;
    },
  },
});
