var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 custom-data-table",attrs:{"headers":_vm.headers,"footer-props":{'items-per-page-options':[10, 20, 50, 100, 200, 500]},"items-per-page":_vm.queryData.limit,"items":_vm.listData.skus,"server-items-length":_vm.listData.total,"sort-by":"stockOutTime"},on:{"update:sort-by":_vm.onSortByChange,"update:sort-desc":_vm.onSortDescChange,"update:page":_vm.changePage,"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap justify-center justify-md-space-between gap-4 px-4 py-4 border-b data-table-top"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('h5',{staticClass:"text-h6"},[_vm._v("List Items")])]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"text-button ml-3",attrs:{"color":"default"},on:{"click":_vm.handleViewHistory}},[_vm._v("View History")])],1)])]},proxy:true},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sku)+" ")]}},{key:"item.quantityOut",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantityOut)+" ")]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unit)+" ")]}},{key:"item.priceOut",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.priceOut))+" ")]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.totalValue))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }