<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card class="dialog-form">
        <v-card-title v-if="itemEdit">Edit Stock Out</v-card-title>
        <v-card-title v-if="!itemEdit">Create Stock Out</v-card-title>
        <v-btn class="close-button" @click="dialog = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="#1D1B20" />
          </svg>
        </v-btn>
        <form>
          <v-autocomplete
              v-model="variantId"
              :items="allSkuOptions"
              :error-messages="variantErrors"
              item-text="sku"
              item-value="_id"
              label="SKU"
              required
              v-if="!itemEdit"
              :disabled="!!itemEdit"
              @change="$v.variantId.$touch()"
              @blur="$v.variantId.$touch()"
          ></v-autocomplete>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="stockOutTime"
              transition="scale-transition"
              offset-y
              v-if="!itemEdit"
              min-width="auto"
              :disabled="!!itemEdit"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="stockOutTime"
                  :error-messages="stockOutTimeErrors"
                  label="Stock Out Time"
                  prepend-icon="mdi-calendar"
                  readonly
                  :disabled="!!itemEdit"
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="stockOutTime"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(stockOutTime)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
              v-model="quantityOut"
              :error-messages="quantityErrors"
              label="Quantity Out"
              type="number"
              required
              v-if="!itemEdit"
              @input="$v.quantityOut.$touch()"
              @blur="$v.quantityOut.$touch()"
          ></v-text-field>
          <v-text-field
              v-model="reason"
              :error-messages="reasonErrors"
              label="Reason"
              required
              @input="$v.reason.$touch()"
              @blur="$v.reason.$touch()"
          ></v-text-field>
          <v-text-field
              v-model="description"
              label="Description"
              required
              @input="$v.description.$touch()"
              @blur="$v.description.$touch()"
          ></v-text-field>
          <v-card-actions class="flex">
            <v-btn class="mr-4" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submit">
              Complete
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiManagement } from '@/services/apis/apiManagement'
import { convertToTimestamp } from '@/utils/format'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'EditStockOut',
  mixins: [validationMixin],
  validations: {
    reason: { required },
    description: {},
    variantId: { required },
    quantityOut: { required },
    stockOutTime: { required },
  },

  data: () => ({
    reason: '',
    description: '',
    variantId: null,
    quantityOut: null,
    stockOutTime: moment().format('YYYY-MM-DD'),
    menu: false,
    dialog: false,
  }),

  props: {
    actionShowModal: { type: Number, default: 0 },
    allSkuOptions: { type: Array },
    itemEdit: { type: Object, default: null }
  },

  watch: {
    actionShowModal() {
      this.dialog = true
      if (this.itemEdit) {
        this.reason = this.itemEdit.reason
        this.description = this.itemEdit.description
      }
    },
    dialog(newVal) {
      if (!newVal) {
        this.clear()
      }
    }
  },

  computed: {
    variantErrors() {
      const errors = []
      if (!this.$v.variantId.$dirty) {
        return errors
      }
      !this.$v.variantId.required && errors.push('Item is required')
      return errors
    },
    reasonErrors() {
      const errors = []
      if (!this.$v.reason.$dirty) {
        return errors
      }
      !this.$v.reason.required && errors.push('Supplier reason is required.')
      return errors
    },
    stockOutTimeErrors() {
      const errors = []
      if (!this.$v.stockOutTime.$dirty) {
        return errors
      }
      !this.$v.stockOutTime.required && errors.push('Datetime is required')
      return errors
    },
    quantityErrors() {
      const errors = []
      if (!this.$v.quantityOut.$dirty) {
        return errors
      }
      !this.$v.quantityOut.required && errors.push('Quantity In is required')
      return errors
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()

      const formData = {

        reason: this.reason,
        description: this.description
      }

      if (!this.itemEdit) {
        formData.variantId = this.variantId
        formData.stockOutTime = convertToTimestamp(this.stockOutTime, 'YYYY-MM-DD')
        formData.type = 'Custom'
        formData.quantityOut = this.quantityOut
      }

      try {
        this.itemEdit ? await apiManagement.updateStockOut(this.itemEdit.id, formData) : await apiManagement.createStockOut(formData)
        this.$store.commit('setSuccessMessage', 'Save stock in successful')
        this.dialog = false
        this.$emit('success')
      } catch (e) {
        if (e?.response?.status === 400) {
          this.$store.commit('setErrorMessage', e.response.data.message)
        } else {
          this.$store.commit('setErrorMessage', 'Save stock in has error')
        }
      }

    },
    clear() {
      this.$v.$reset()
      this.reason = ''
      this.description = ''
      this.variantId = null
      this.stockOutTime = moment().format('YYYY-MM-DD')
    },
  },
}

</script>

<style lang="scss" scoped>
.dialog-form {
  padding: 34px 31px;
}

.v-card__title {
  padding: 0 !important;
  margin-bottom: 32px;
}

.close-button {
  position: absolute;
  top: 26px;
  right: 32px;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  height: unset !important;
  min-width: unset !important;
  border-radius: 0;
}

::v-deep .v-input__prepend-outer {
  display: none !important;
}

.v-card__actions {
  justify-content: flex-end;
  padding-bottom: 0;
}
</style>
